<template>
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">{{ $t('cancelCheckout.redirectedSoon') }}</h2>
      <p class="mb-3">
        <b-spinner></b-spinner>
      </p>

      <b-img fluid :src="imgUrl" alt="Cancel checkout page" />
    </div>
  </div>
</template>

<script>
import { BImg, BSpinner } from 'bootstrap-vue'

import { computed, onUnmounted } from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import { callHttpsCallableFunction } from '@/api/firebase'

import userStoreModule from './admin/users/userStoreModule'
import UserEntity from '../entities/user.entity'
import { CustomerEntity } from '../entities/customer.entity'
import { cF } from '../utils/cloud-functions'
import opticStoreModule from './sections/optics/opticStoreModule'

export default {
  name: 'CancelCheckout',

  components: {
    BImg,
    BSpinner,
  },

  setup() {
    const uid = router.currentRoute.query.uid
    const oid = router.currentRoute.query.oid

    if (!uid) {
      router.push('/')
    }

    const USER_APP_STORE_MODULE_NAME = 'user'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(USER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)

      store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME)
    })

    /**
     * Cancels the user checkout, erasing its account.
     */
    const cancel = async () => {
      try {
        /**
         * @type {UserEntity}
         */
        const user = await store.dispatch('user/fetchOneUser', uid)

        if (!user || (user.adminDocRef && !oid)) {
          router.push('/')
        }

        /**
         * @type {CustomerEntity}
         */
        const customer = await store.dispatch('user/fetchOneCustomer', uid)

        if (!customer) {
          router.push('/')
        }

        await store.dispatch('auth/logout')

        await store.dispatch('user/deleteUser', {
          path: `users/${uid}`,
          userId: uid,
        })
        await store.dispatch('user/deleteCustomer', uid)

        await callHttpsCallableFunction(cF.deleteUser, { uid })

        if (!oid) {
          return
        }
        
        await store.dispatch('optic/deleteOptic', {
          path: user.adminDocRef,
          userId: uid,
        })
      } finally {
        router.push('/')
      }
    }
    cancel()

    const imgUrl = computed({
      get: () =>
        store.state.appConfig.layout.skin === 'dark'
          ? require('@/assets/images/pages/coming-soon-dark.svg')
          : require('@/assets/images/pages/coming-soon.svg'),
    })

    return {
      imgUrl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
